@import "@/assets/scss/style.scss";

.app-sidebar .ant-layout-sider {
    position: relative;
    background-color: $sidebar-background;
    height: 100%;
    z-index: 3;

    .trigger-btn {
        position: absolute;
        width: 44px;
        height: 40px;
        top: 55px;
        left: -44px;
        z-index: 1;
        @media (max-width: 600px) {
            top: 115px;
        }
    }

    .trigger-btn--collapsed--not {
        @media (max-width: 600px) {
            left: 0;
        }
    }
}

.ant-layout-sider--collapsed--not {
    @media (max-width: 600px) {
        flex: 0 0 90vw !important;
        max-width: 90vw !important;
        min-width: 90vw !important;
        width: 90vw !important;
    }
}

.backdrop {
    position: fixed;
    bottom: 0;
    right: 20px;
    width: calc(100vw - 56px);
    background-color: $backdrop;
    z-index: 2;
}

.full-cover {
    height: 100vh;
}

.loader {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(#000, 0.1);
}

::v-deep .ant-layout-content > div > h3 {
    color: $grey-8;

    &:not(:first-child) {
        margin-top: 32px;
    }
}

@media (min-width: 1500px) {
    .backdrop,
    .trigger-btn {
        display: none;
    }
}

@media (max-width: 512px) {
    .app-sidebar {
        max-width: 100%;

        .ant-layout-content {
            max-width: 100%;
        }
    }
}

// @media (max-width: 440px) {
//     .app-sidebar .ant-layout-content {
//         max-width: 80%;
//     }
// }
